<template>
  <div id="app" class="bg-gray-900 text-white min-h-screen flex flex-col" :style="backgroundStyle">
    <nav class="bg-black bg-opacity-50 p-4 flex items-center justify-between relative" style="margin-top: 40px;">
      <div class="absolute left-0 pl-[calc(24rem-190px)] hidden lg:block">
        <h1 class="text-4xl font-bold flex items-center">
          DJ&nbsp;&nbsp;&nbsp;B A L S A M
        </h1>
      </div>
      <div class="flex-grow flex justify-center">
        <ul class="flex space-x-4">
          <li>
            <router-link to="/" class="text-lg hover:text-green-500">Home</router-link>
          </li>
          <li>
            <router-link to="/about" class="text-lg hover:text-green-500">About</router-link>
          </li>
          <li>
            <router-link to="/player" class="text-lg hover:text-green-500">Player</router-link>
          </li>
          <li>
            <router-link to="/gallery" class="text-lg hover:text-green-500">Gallery</router-link>
          </li>
        </ul>
      </div>
      <div class="absolute icons-container flex flex-col items-start">
        <div class="flex space-x-4">
          <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
            <img src="@/assets/fb.png" alt="Facebook" class="h-6 w-6 hover:opacity-75">
          </a>
          <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
            <img src="@/assets/insta.png" alt="Instagram" class="h-6 w-6 hover:opacity-75">
          </a>
          <a href="https://www.soundcloud.com" target="_blank" rel="noopener noreferrer">
            <img src="@/assets/sc.png" alt="SoundCloud" class="h-6 w-6 hover:opacity-75">
          </a>
        </div>
      </div>
      <div class="absolute sign-container p-2 rounded-lg">
        <router-link to="/signin" class="text-white hover:text-gray-300 block mb-1">Sign In</router-link>
        <router-link to="/signup" class="text-white hover:text-gray-300 block">Sign Up</router-link>
      </div>
    </nav>
    <router-view class="flex-grow"/>
    <div class="social-icons-container mt-4 flex justify-center">
      <div class="flex space-x-4">
        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
          <img src="@/assets/fb.png" alt="Facebook" class="h-6 w-6 hover:opacity-75">
        </a>
        <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
          <img src="@/assets/insta.png" alt="Instagram" class="h-6 w-6 hover:opacity-75">
        </a>
        <a href="https://www.soundcloud.com" target="_blank" rel="noopener noreferrer">
          <img src="@/assets/sc.png" alt="SoundCloud" class="h-6 w-6 hover:opacity-75">
        </a>
      </div>
    </div>
    <footer class="bg-black bg-opacity-50 text-center py-4 fixed bottom-0 w-full">
      <p>Warsaw 2024</p>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      backgroundStyle: {
        backgroundImage: `url(${require('@/assets/wallpaper.png')})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }
    }
  }
}
</script>

<style scoped>
html, body {
  height: 100%;
  font-family: 'Helvetica', sans-serif;
}

#app nav, #app ul, #app li, #app h1, #app p {
  font-weight: bold; /* Bold for elements within this component */
}

.text-4xl {
  font-size: 2.25rem; /* Increased font size for DJ BALSAM */
}

.text-lg {
  font-size: 1.125rem;
}

.sign-container {
  background-color: rgba(0, 0, 0, 0.7); /* Darker background */
  padding: 10px;
  border-radius: 8px;
  position: absolute; /* Position absolute */
  top: 70px; /* Adjust this value to move the sign-in container lower */
  right: 35px; /* Position the sign-in container at the right edge */
}

.ml-4 {
  margin-left: 1rem; /* Margin left for the icon */
}

.h-10 {
  height: 2.5rem; /* Height for the icon */
}

.w-10 {
  width: 2.5rem; /* Width for the icon */
}

.icons-container {
  position: absolute; /* Make sure the position is absolute */
  top: 20px; /* Adjust this value to move icons lower */
  right: 20px; /* Position the icons at the right edge */
}

@media screen and (max-width: 768px) {
  .icons-container {
    top: 160px; /* Move icons 100px lower */
    left: 57%;
    transform: translateX(-50%);
  }

  .social-icons-container {
    display: flex;
    margin-top: 2rem; /* Adjust this value to move icons lower */
  }

  .sign-container {
    position: static; /* Make the sign-in container static */
    margin-top: 2rem; /* Adjust this value to move the sign-in container lower */
  }

  footer {
    display: none; /* Hide footer on smaller screens */
  }
}

.social-icons-container {
  display: none;
}
</style>
