
<template>
  <div class="flex items-start justify-center min-h-screen pt-20" :style="backgroundStyle">
    <div class="bg-black bg-opacity-50 p-8 rounded-lg text-center w-3/4 max-w-4xl mx-auto">
      <h1 class="text-4xl mb-4 text-white">Gallery</h1>
      <p class="text-lg text-white">This page is under construction  </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  data() {
    return {
      backgroundStyle: {
        backgroundImage: `url(${require('@/assets/wallpaper.png')})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }
    }
  }
};
</script>

<style scoped>
h1, p {
  font-family: Tahoma, sans-serif;
  color: #ffffff;
}
</style>


<!-- <template>
  <div class="flex items-start justify-center min-h-screen pt-20" :style="backgroundStyle">
    <div class="bg-black bg-opacity-50 p-8 rounded-lg text-center w-3/4 max-w-4xl mx-auto">
      <div class="flex items-center justify-center mb-4">
        <button @click="prevImage" class="text-white text-3xl mr-4">&lt;</button>
        <img :src="currentImage" :alt="'Image ' + (currentIndex + 1)" class="main-image"/>
        <button @click="nextImage" class="text-white text-3xl ml-4">&gt;</button>
      </div>
      <div class="thumbnails flex justify-center mt-4">
        <img
            v-for="(image, index) in images"
            :key="index"
            :src="image"
            :alt="'Thumbnail ' + (index + 1)"
            class="thumbnail mx-2 cursor-pointer"
            @click="currentIndex = index"
            :class="{ 'border-2 border-white': currentIndex === index }"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GalleryPage',
  data() {
    return {
      currentIndex: 0,
      images: [
        require('@/assets/gallery/adami.jpg'),
        require('@/assets/gallery/woju.jpg'),
        require('@/assets/gallery/athl.jpg'),
        require('@/assets/gallery/smol.png'),
        require('@/assets/gallery/zanet.png'),
        // Add more images as needed
      ],
      backgroundStyle: {
        backgroundImage: `url(${require('@/assets/wallpaper.png')})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }
    }
  },
  computed: {
    currentImage() {
      return this.images[this.currentIndex];
    }
  },
  methods: {
    prevImage() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      } else {
        this.currentIndex = this.images.length - 1;
      }
    },
    nextImage() {
      if (this.currentIndex < this.images.length - 1) {
        this.currentIndex++;
      } else {
        this.currentIndex = 0;
      }
    }
  }
};
</script>

<style scoped>
h1 {
  font-family: Tahoma, sans-serif;
  color: #ffffff;
}

button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  outline: none;
}

button:hover {
  color: #cccccc;
}

.main-image {
  max-width: 100%;
  max-height: 80vh;
  object-fit: contain;
}

.thumbnails {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.thumbnail {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
  border: 2px solid transparent;
  transition: border-color 0.3s;
}

.thumbnail:hover {
  border-color: #cccccc;
}

.border-2 {
  border-width: 2px;
}
</style>

*/ -->