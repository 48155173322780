import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './assets/css/tailwind.css';
import VueAudioPlayer from '@liripeng/vue-audio-player'

Vue.use(VueAudioPlayer)


// Register FontAwesomeIcon component globally

Vue.config.productionTip = false;

new Vue({
    router,
    render: h => h(App)
}).$mount('#app');
