<template>
  <div class="flex items-start justify-center min-h-screen pt-20" :style="backgroundStyle">
    <div class="bg-black bg-opacity-70 p-8 rounded-lg text-center w-4/5 max-w-2xl mx-auto mt-12">
      <h1 class="text-5xl font-bold mb-6 text-white">Welcome to DJ Balsam Site</h1>
      <p class="text-lg text-white custom-paragraph mt-6">
        Hi I'm Adam, I'm a mainly house and electronic DJ From Warsaw. What I like most is to look for new music and discover fresh or exotic rhythms that will break us out of the monotony of the daily routine. Enjoy music and multimedia. Log in to download sets in excellent quality to your device!
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  data() {
    return {
      backgroundStyle: {
        backgroundImage: `url(${require('@/assets/wallpaper.png')})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }
    }
  }
};
</script>

<style scoped>
h1 {
  font-family: Tahoma, sans-serif;
  color: #ffffff;
  font-size: 2.5rem; /* Increased font size */
  font-weight: bold; /* Added boldness */
  margin-bottom: 1.5rem; /* Increased bottom margin */
}

.custom-paragraph {
  font-size: 1.25rem; /* Larger font size */
  line-height: 2; /* Increased line spacing */
  word-wrap: break-word; /* Ensure text wraps properly */
  margin-top: 1.5rem; /* Increased top margin */
}

.mt-12 {
  margin-top: 50px; /* Custom class to add 50px margin on top */
}
</style>
