import Vue from 'vue';
import VueRouter from 'vue-router';
import HomePage from '../components/HomePage.vue';
import AboutPage from '../components/AboutPage.vue';
import PlayerPage from '../components/PlayerPage.vue';
import GalleryPage from '../components/GalleryPage.vue';

Vue.use(VueRouter);

let routes;
routes = [
    {
        path: '/',
        name: 'HomePage',
        component: HomePage
    },
    {
        path: '/about',
        name: 'AboutPage',
        component: AboutPage
    },
    {
        path: '/player',
        name: 'PlayerPage',
        component: PlayerPage
    },
    {
        path: '/gallery',
        name: 'GalleryPage',
        component: GalleryPage
    }
];

const router = new VueRouter({
    routes
});

export default router;
