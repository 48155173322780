<template>
  <div class="flex items-start justify-center min-h-screen pt-20" :style="backgroundStyle">
    <div class="bg-black bg-opacity-50 p-8 rounded-lg text-center w-3/4 max-w-4xl mx-auto">
      <h1 class="text-4xl mb-4 text-white">About</h1>
      <p class="text-lg text-white">This page is under construction  </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  data() {
    return {
      backgroundStyle: {
        backgroundImage: `url(${require('@/assets/wallpaper.png')})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }
    }
  }
};
</script>

<style scoped>
h1, p {
  font-family: Tahoma, sans-serif;
  color: #ffffff;
}
</style>
