<template>
  <div class="flex items-start justify-center min-h-screen pt-20" :style="backgroundStyle">
    <div :class="['audio-player-container', { 'glitter-effect': isPlaying }]" class="p-6 rounded-lg text-center w-4/5 max-w-3xl mx-auto mb-12">
      <div class="black-strip"></div>
      <div class="title-container">
        <div class="title-background"></div>
        <div class="name">
          {{ currentAudioName || audioList[0].title }}
        </div>
      </div>
      <div class="thumbnail-container">
        <img :src="currentArtwork" class="artwork-thumbnail" @click="showFullImage = true" alt="Artwork Thumbnail">
      </div>
      <vue-audio-player
          ref="audioPlayer"
          :audio-list="audioList"
          :before-play="handleBeforePlay"
          :show-play-loading="false"
          @play="handlePlay"
          @pause="handlePause"
          @ended="handleEnded"
      >
      </vue-audio-player>
      <div v-if="showFullImage" class="modal" @click="showFullImage = false">
        <img :src="currentArtwork" class="full-artwork" alt="Full Artwork">
      </div>
    </div>
  </div>
</template>

<script>
import VueAudioPlayer from "@liripeng/vue-audio-player";

export default {
  components: {
    VueAudioPlayer,
  },

  data() {
    return {
      currentAudioName: "",
      showFullImage: false,
      isPlaying: false,
      currentArtwork: require('@/assets/pastel.jpg'),
      audioList: [
        {
          src: require('@/assets/music/24.wav'),
          title: "Dj B a l s a m 05/24",
          artist: "Dj Balsam",
          album: "05/24",
          artwork: [
            {
              src: require('@/assets/pastel.jpg'),
              sizes: "512x512",
              type: "image/jpeg"
            }
          ],
        },
        {
          src: require('@/assets/music/djbalsam 02.23.wav'),
          title: "Dj B a l s a m 02/23",
          artist: "Dj Balsam",
          album: "02/23",
          artwork: [
            {
              src: require('@/assets/balsam0223.png'),
              sizes: "512x512",
              type: "image/png"
            }
          ],
        },
        {
          src: require('@/assets/music/djbalsam 10.23.wav'),
          title: "Dj B a l s a m 10/23",
          artist: "Dj Balsam",
          album: "10/23",
          artwork: [
            {
              src: require('@/assets/balsam1023.png'),
              sizes: "512x512",
              type: "image/png"
            }
          ],
        },
      ],
      backgroundStyle: {
        backgroundImage: `url(${require('@/assets/wallpaper.png')})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }
    };
  },

  methods: {
    handleBeforePlay(next) {
      const currentIndex = this.$refs.audioPlayer.currentPlayIndex;
      this.currentAudioName = this.audioList[currentIndex].title;
      this.currentArtwork = this.audioList[currentIndex].artwork[0].src;
      next(); // Start play
    },

    handlePlay() {
      this.isPlaying = true;
    },

    handlePause() {
      this.isPlaying = false;
    },

    handleEnded() {
      this.isPlaying = false;
      console.log("ended");
    },
  },
};
</script>

<style scoped>
.audio-player-container {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 50px;
  transition: all 0.5s ease;
  position: relative;
}

.black-strip {
  position: absolute;
  top: 37px; /* Move the strip 3px up from the previous 40px */
  left: 0;
  width: 100%;
  height: 50px;
  background-color: black;
}

.title-container {
  position: relative;
  margin-bottom: 20px;
}

.title-background {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: -1; /* Behind the title text */
}

.name {
  color: #fff;
  text-align: center;
  line-height: 80px;
  font-weight: bold;
  font-size: 32px;
  font-family: 'Helvetica', sans-serif;
}

.thumbnail-container {
  display: flex;
  justify-content: center; /* Center the thumbnail horizontally */
  margin-bottom: 20px; /* Space before the audio player controls */
}

.artwork-thumbnail {
  width: 350px; /* Increased size from the previous 300px */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover;
  cursor: pointer;
  border-radius: 8px; /* Rounded corners */
}

.full-artwork {
  width: auto;
  max-width: 90%;
  max-height: 90vh;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes glitter {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 100%; /* Adjusted for a smoother scroll */
  }
}

.glitter-effect {
  background-image: url('@/assets/glitter.png'); /* Replace with your glitter image */
  background-size: 50%; /* Adjusted size for better visibility */
  background-repeat: repeat;
  animation: glitter 4s linear infinite; /* Slower animation for better visibility */
}
</style>
